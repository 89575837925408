import React from 'react';
import { PageProps, graphql } from 'gatsby';
import Seo from '../components/Seo';
import FeaturesComponent from '../components/Features';
import FooterCredit from '../components/FooterCredit';
import { Constrain, GeneralBody } from '../components/Containers';
import { Article, PageTitle } from '../components/Typography';

export default function Features(
	props: PageProps<GatsbyTypes.FeaturesPageDataQuery>
) {
	const { data } = props;
	return (
		<>
			<Seo
				title="WPEForm Features - Why WPEForm is best WordPress Form Builder"
				description="Explore all the features and perks WPEForm WordPress Form Builder has to offer. From various form elements, to payments, subscription, quizzes and integrations, we have you covered."
			/>
			<Constrain>
				<GeneralBody>
					<PageTitle className="centered narrow">
						Explore all the features WPEForm has to offer!
					</PageTitle>
					<Article className="subheader">
						Wondering why you would purchase WPEForm? We shouldn't say that
						WPEForm is the best WordPress Form Builder out there, so kindly see
						for yourself.
					</Article>
				</GeneralBody>
			</Constrain>
			<FeaturesComponent
				showAvailabilityTags
				layout="singlepage"
				features={data.features}
			/>
			<FooterCredit />
		</>
	);
}
export const query = graphql`
	query FeaturesPageData {
		features: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/features/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						youtubeVideoId
						heading
						subHeading
						secondaryHeading
						secondarySubHeading
						desc
						neededPlan
						released
						image {
							childImageSharp {
								gatsbyImageData(
									layout: CONSTRAINED
									width: 616
									placeholder: BLURRED
								)
							}
						}
						catches {
							title
							desc
							image {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 128
										placeholder: TRACED_SVG
									)
								}
							}
						}
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;
